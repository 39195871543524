import React, { Component } from 'react'
import CarWithDriver from '../../../components/car-with-driver'

export default class index extends Component {
  render() {
    return (
      <div>
        <CarWithDriver prefix="th" />
      </div>
    )
  }
}
